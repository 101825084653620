<template>
  <div>
    <div class="tab">
      <ul class="d-flex">
        <li
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(0)"
          :class="{ tab_active: type == 0 }"
        >
          付费课程资料
        </li>
        <li
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(1)"
          :class="{ tab_active: type == 1 }"
        >
          免费课程资料
        </li>
      </ul>
    </div>
    <!-- <div class="tabs" v-if="type == 1">
      <ul class="d-flex align-center">
        <li
          class="tabs-item d-flex align-center justify-center cursor"
          @click="tabsIndex(0)"
        >
          <div
            class="d-flex align-center justify-center"
            :class="{ tabs_active: currIndex == 0 }"
          >
            付费课程资料
          </div>
        </li>
        <li class="line"></li>
        <li
          class="tabs-item d-flex justify-center cursor"
          @click="tabsIndex(1)"
        >
          <div
            class="d-flex align-center justify-center"
            :class="{ tabs_active: currIndex == 1 }"
          >
            免费课程资料
          </div>
        </li>
      </ul>
    </div> -->
    <div class="content">
      <div class="lists">
        <ul v-for="item in menulist" :key="item.id">
          <li>
            <div
              class="first d-flex align-center cursor"
              @click.stop="toggleChildren(item)"
            >
              <img
                class="first-img"
                :class="{ transform: item.expanded }"
                src="../../assets/img/fold_big.png"
                alt=""
              />
              <div class="firit-name">{{ item.packageName }}</div>
              <!-- <div class="num">135道</div> -->
              <!-- <div class="btn d-flex align-center justify-center">去做题</div> -->
            </div>
            <el-collapse-transition>
              <ul v-show="item.expanded" class="second">
                <li
                  class="item d-flex align-center justify-between"
                  v-for="child in item.documents"
                  :key="child.id"
                >
                  <div class="item-l d-flex align-center">
                    <img
                      class="item-img"
                      src="../../assets/img/pdf.png"
                      alt=""
                    />
                    <div class="item-name d-flex flex-column justify-between">
                      {{ child.name }}
                      <div class="item-time">
                        下载数：{{ child.hits }}
                        <!-- <span>下载数：{{ item.hits }}</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="item-r d-flex align-center">
                    <a
                      class="item-btn d-flex align-center justify-center cursor"
                      :href="child.fileurl"
                    >
                      下载
                    </a>
                  </div>
                </li>
              </ul>
            </el-collapse-transition>
          </li>
        </ul>
      </div>
      <v-pagination
        v-show="total > display"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
export default {
  data() {
    return {
      total: 0, // 记录总条数
      display: 8, // 每页显示条数
      current: 1, // 当前的页数
      type: 0,
      currIndex: 0,
      list: "",
      menulist: []
    };
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.payDoc();
  },
  methods: {
    toggleChildren(item) {
      // console.log(item)
      item.expanded = !item.expanded;
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      // this.getDetails();
      if (this.type == 0) {
        this.payDoc();
      } else {
        this.freeDoc();
      }
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    tabIndex(id) {
      if (this.type != id) {
        this.type = id;
        if (id == 0) {
          this.payDoc();
        } else {
          this.freeDoc();
        }
      }
    },
    //付费资料包
    async payDoc(params) {
      let _this = this;
      const user = JSON.parse(window.localStorage.getItem("user"));
      let data = user.studentId
      const res = await this.$ajaxRequest('get', 'payDoc', '', data)
      for (let i in res.data) {
        res.data[i].expanded = false;
      }
      _this.menulist = res.data;
    },
    //免费资料包
    async freeDoc(params) {
      let _this = this;
      const user = JSON.parse(window.localStorage.getItem("user"));
      let data = user.studentId
      const res = await this.$ajaxRequest('get', 'freeDoc', '', data)
      for (let i in res.data) {
        res.data[i].expanded = false;
      }
      _this.menulist = res.data;
    },
  }
};
</script>

<style lang="less" scoped>
.tab_active {
  border-color: #5f2eff !important;
  color: #5f2eff !important;
  font-weight: bold;
}
.tabs_active {
  border-color: #5f2eff !important;
  color: #5f2eff !important;
  font-weight: bold;
}
.tab {
  width: 1000px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding-top: 23px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .tab-item {
    width: 150px;
    padding-bottom: 11px;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    margin-left: 15px;
    font-size: 20px;
    color: #666666;
  }
}
.tabs {
  width: 1000px;
  height: 88px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  .tabs-item {
    width: 499px;
    height: 88px;
    font-size: 20px;
    color: #666666;
    div {
      height: 88px;
      width: 250px;
      font-size: 20px;
      color: #666666;
      border-bottom: 3px solid transparent;
      box-sizing: border-box;
    }
  }
  .line {
    width: 1px;
    height: 50px;
    background: #cccccc;
  }
}
.content {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  min-height: 546px;
  .list {
    padding: 26px 36px;
    box-sizing: border-box;

    .item {
      height: 96px;
      background: #ffffff;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f9ff;
      .item-l {
        .item-img {
          height: 55px;
          width: 45px;
          margin-right: 20px;
        }
        .item-name {
          font-size: 18px;
          color: #333333;
          height: 55px;
          .item-time {
            color: #999999;
            font-size: 14px;
            span {
              margin-left: 23px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
      .item-r {
        .download {
          font-size: 16px;
          color: #999999;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .study {
          // margin-left: auto;
          margin-right: 20px;
          color: #5f2eff;
        }
        .study-w {
          // margin-left: auto;
          margin-right: 20px;
          color: #999999;
        }
        .item-btn {
          width: 104px;
          height: 40px;
          background: #f3efff;
          border: 1px solid #5f2eff;
          border-radius: 20px;
          font-size: 16px;
          color: #5f2eff;
        }
      }
    }
  }
  .lists {
    padding: 26px 36px;
    box-sizing: border-box;
    max-height: 650px;
    overflow-x: scroll;
  }
  .first {
    height: 76px;
    border-bottom: 1px solid #f6f9ff;
    box-sizing: border-box;
    .first-img {
      width: 20px;
      height: 11px;
      margin-right: 10px;
      transition: 0.35s;
      -moz-transition: 0.35s;
      -webkit-transition: 0.35s;
      -o-transition: 0.35s;
    }
    .firit-name {
      font-size: 18px;
      color: #333333;
    }

    .num {
      font-size: 16px;
      color: #999999;
      width: 100px;
      text-align: center;
      margin-right: 16px;
      margin-left: auto;
    }
    .btn {
      height: 32px;
      width: 80px;
      font-size: 16px;
      color: #ffffff;
      margin-right: 7px;
    }
  }
  .second {
    border-bottom: 1px solid #f6f9ff;
    box-sizing: border-box;
    margin-left: 20px;
    .item {
      height: 96px;

      background: #ffffff;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f9ff;
      .item-l {
        .item-img {
          height: 55px;
          width: 45px;
          margin-right: 20px;
        }
        .item-name {
          font-size: 18px;
          color: #333333;
          height: 55px;
          .item-time {
            color: #999999;
            font-size: 14px;
            span {
              margin-left: 23px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
      .item-r {
        .download {
          font-size: 16px;
          color: #999999;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .study {
          // margin-left: auto;
          margin-right: 20px;
          color: #5f2eff;
        }
        .study-w {
          // margin-left: auto;
          margin-right: 20px;
          color: #999999;
        }
        .item-btn {
          width: 104px;
          height: 40px;
          background: #f3efff;
          border: 1px solid #5f2eff;
          border-radius: 20px;
          font-size: 16px;
          color: #5f2eff;
        }
      }
    }
  }
}
</style>
